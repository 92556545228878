import React, { useState, useRef, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import cls from './joindialog.module.scss';


import logo from '../../../../img/logo.svg';

interface JoinDialogParams {
  meetingToken: string;
  onJoin: Function;
}

const JoinDialog = ({ meetingToken, onJoin }: JoinDialogParams) => {
  let textInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [userName, setUserName] = useState(
    jwtDecode<{ u: string }>(meetingToken).u,
  );

  useEffect(() => {
    textInput.current.focus();
  }, [textInput])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onJoin(userName);
  };

  return (
    <React.Fragment>
      <div className={cls.joinDialog}>
        <img src={logo} alt='logo'/>
        <div className={cls.inner}>
          <span>Please enter your name:</span>
          <form onSubmit={onSubmit}>
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              ref={textInput}
            />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JoinDialog;
