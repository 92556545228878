import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import {
  faVideoSlash,
  faMicrophoneAltSlash,
  faDesktop,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DailyCameraError } from '../../../../DailyTypes';

import TrayButton from '../TrayButton/TrayButton';


import cls from './tray.module.scss';

declare global {
  interface Window {
    callObject: any;
  }
}

interface TrayParam {
  callObject: any;
  isVideoMirrored: boolean;
  setIsVideoMirrored: Function;

}

const Tray = ({
  callObject,
  isVideoMirrored,
  setIsVideoMirrored,

}: TrayParam) => {
  /**
   * Gets [isCameraMuted, isMicMuted, isLocalParticipant].
   * This function is declared outside Tray() so it's not recreated every render
   * (which would require us to declare it as a useEffect dependency).
   */
  function getStreamStates(callObject: any): any[] {
    let isCameraMuted, isMicMuted, isLocalParticipant;
    if (
      callObject &&
      callObject.participants() &&
      callObject.participants().local
    ) {
      const localParticipant = callObject.participants().local;
      isCameraMuted = !localParticipant.video;
      isMicMuted = !localParticipant.audio;
      isLocalParticipant = true;
    }
    return [isCameraMuted, isMicMuted, isLocalParticipant];
  }

  const [isCameraMuted, setCameraMuted] = useState(false);
  const [isMicMuted, setMicMuted] = useState(false);
  const [availableCameras, setAvailableCameras] = useState([]);
  const [currentCamera, setCurrentCamera] = useState('');
  const [isCameraError, setIsCameraError] = useState(false);

  function toggleCamera() {
    callObject.setLocalVideo(isCameraMuted);
  }

  function toggleMic() {
    // if (callObject.properties.audioSource){
    //   callObject.properties.audioSource.enabled = isMicMuted;
    //   return;

    // }

    callObject.setLocalAudio(isMicMuted);
  }

  useEffect(() => {
    callObject.enumerateDevices().then((devices: { devices: [] }) => {
      const video = devices.devices.filter(
        (device: any) => device.kind === 'videoinput',
      );
      setAvailableCameras(video);
    });
  }, [callObject]);

  useEffect(() => {
    if (!currentCamera) {
      return;
    }

    callObject.enumerateDevices().then((devices: any) => {
      const camera = devices.devices.find(
        (device: any) =>
          device.deviceId === currentCamera && device.kind === 'videoinput',
      );

      const cameraLabel = camera.label.toLowerCase();
      if (cameraLabel.indexOf('front') !== -1) {
        setIsVideoMirrored(true);
      } else if (cameraLabel.indexOf('rear') !== -1) {
        setIsVideoMirrored(false);
      } else if (cameraLabel.indexOf('back') !== -1) {
        setIsVideoMirrored(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCamera, setIsVideoMirrored]);
  /**
   * Start listening for participant changes when callObject is set (i.e. when the component mounts).
   * This event will capture any changes to your audio/video mute state.
   */
  useEffect(() => {
    if (!callObject) return;

    window.callObject = callObject; // TODO debug remove

    const getCurrentCamera = () => {
      callObject.getInputDevices().then((devices: any) => {
        setCurrentCamera(devices.camera.deviceId);

        // alert(JSON.stringify(devices,null,4));
      });
    };

    function handleNewParticipantsState() {
      const [isCameraMuted, isMicMuted, isLocalParticipant] = getStreamStates(
        callObject,
      );
      setCameraMuted(isCameraMuted);
      setMicMuted(isMicMuted);
      if (isLocalParticipant) {
        getCurrentCamera();
      }
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    callObject.on('participant-updated', handleNewParticipantsState);
    callObject.on('camera-error', (error: DailyCameraError) => {
      Sentry.captureMessage(error.errorMsg.errorMsg);
      Sentry.captureMessage('camera-error');
      setIsCameraError(true);
    });

    window.addEventListener('message', (msg) => {
      // debug
      if (
        msg.data &&
        msg.data.action &&
        msg.data.action !== 'network-quality-change' &&
        msg.data.source !== "@devtools-page"
      ) {
        console.log(msg.data);
      }
    });

    // Stop listening for changes in state
    return function cleanup() {
      callObject.off('participant-updated', handleNewParticipantsState);
    };
  }, [callObject, setIsVideoMirrored]);

  const onCameraChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    callObject.setInputDevices({
      videoDeviceId: event.target.value,
      audioSource: callObject.participants().local.audioTrack // daily, whyyyyy?????
    });
  };

  const mirrorMyVideo = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIsVideoMirrored(!isVideoMirrored);
  };

  const getCameraSelect = () => {
    if (!availableCameras.length) {
      return false;
    }
    return (
      <select
        className={cls.select}
        value={currentCamera}
        onChange={onCameraChange}
      >
        {availableCameras.map((camera: any, key: number) => {
          return (
            <option key={key} value={camera.deviceId}>
              {' '}
              {camera.label}
            </option>
          );
        })}
      </select>
    );
  };

  if (isCameraError) {
    return (
      <div className={cls.tray}>
        <span className={cls.cameraError}>
          Camera not allowed. Please allow it and refresh page
        </span>
      </div>
    );
  }

  //debugger
  return (
    <div className={cls.tray}>
      <TrayButton active={isCameraMuted} onClick={toggleCamera}>
        <FontAwesomeIcon icon={faVideoSlash} />
      </TrayButton>
      <TrayButton active={isMicMuted} onClick={toggleMic}>
        {' '}
        <FontAwesomeIcon icon={faMicrophoneAltSlash} />
      </TrayButton>
      {getCameraSelect()}
      <TrayButton active={isVideoMirrored} onClick={mirrorMyVideo}>
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faDesktop} transform="shrink-10" />
          <FontAwesomeIcon icon={faSyncAlt} />
        </span>
      </TrayButton>
    </div>
  );
};

export default Tray;
