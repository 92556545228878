import React, { useEffect, useReducer } from 'react';

import Tile from '../Tile/Tile';

import {
  initialCallState,
  CLICK_ALLOW_TIMEOUT,
  PARTICIPANTS_CHANGE,
  CAM_OR_MIC_ERROR,
  FATAL_ERROR,
  callReducer,
  isLocal,
  isOwner,
  // isScreenShare,
  //  containsScreenShare,
  //  getMessage
} from './callState';

import cls from './call.module.scss';

const Call = ({
  callObject,
  isVideoMirrored,
}: {
  callObject: any;
  isVideoMirrored: boolean;
}) => {
  const [callState, dispatch] = useReducer(callReducer, initialCallState);

  useEffect(() => {
    const events = [
      'participant-joined',
      'participant-updated',
      'participant-left',
    ];

    const handleNewParticipantsState = () => {
      console.log(callObject.participants());
      dispatch({
        type: PARTICIPANTS_CHANGE,
        participants: callObject.participants(),
      });
    };
    handleNewParticipantsState();

    for (const event of events) {
      callObject.on(event, handleNewParticipantsState);
    }

    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewParticipantsState);
      }
    };
  }, [callObject]);

  /**
   * Start listening for call errors, when the callObject is set.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleCameraErrorEvent(event: any) {
      dispatch({
        type: CAM_OR_MIC_ERROR,
        message:
          (event && event.errorMsg && event.errorMsg.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no camera error when callObject
    // is first assigned.

    callObject.on('camera-error', handleCameraErrorEvent);

    return function cleanup() {
      callObject.off('camera-error', handleCameraErrorEvent);
    };
  }, [callObject]);

  /**
   * Start listening for fatal errors, when the callObject is set.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleErrorEvent(e: any) {
      dispatch({
        type: FATAL_ERROR,
        message: (e && e.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no error when callObject is
    // first assigned.

    callObject.on('error', handleErrorEvent);

    return function cleanup() {
      callObject.off('error', handleErrorEvent);
    };
  }, [callObject]);

  /**
   * Start a timer to show the "click allow" message, when the component mounts.
   */
  useEffect(() => {
    const t = setTimeout(() => {
      dispatch({ type: CLICK_ALLOW_TIMEOUT });
    }, 2500);

    return function cleanup() {
      clearTimeout(t);
    };
  }, []);

  function getTiles() {
    const ownerTiles: any = [];
    const gamersTiles: any = [];

    Object.entries(callState.callItems).forEach(([id, callItem]) => {
      const isLarge = isOwner(callItem); // && !isLocal(id);
      const tile = (
        <Tile
          key={id}
          callItem={callItem}
          isLarge={isLarge}
          isLocalPerson={isLocal(id)}
          isVideoMirrored={isVideoMirrored}
        />
      );
      let command = 'push';
      if (isLocal(id)) {
        command = 'unshift';
      }
      if (isLarge) {
        ownerTiles[command](tile);
      } else {
        gamersTiles[command](tile);
      }
    });
    return [ownerTiles, gamersTiles];
  }

  const [ownerTiles, gamersTiles] = getTiles();

  return (
    <div className={cls.call}>
      <div className={cls.ownerTiles}>{ownerTiles}</div>
      <div className={cls.gamerTiles}>{gamersTiles}</div>
    </div>
  );
};

export default Call;
