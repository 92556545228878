import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


import Page404 from './pages/404/Page404';
import JoinPage from './pages/join/JoinPage';
import cls from './App.module.scss';

import 'react-toastify/dist/ReactToastify.css';


function App() {

  return (
    <div className={cls.app}>
      <ToastContainer />
      <Switch>
      <Route exact path="/join/:roomName/:meetingToken" component={JoinPage} />
      <Route component={Page404} />


      </Switch>

    </div>
  );
}

export default App;
