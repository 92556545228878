import React, { useState } from 'react';

import * as Sentry from '@sentry/browser';

import cls from './audioplayer.module.scss';

interface AudioPlayerParams {
  audioFile: File;
  audioContext: {
    context: AudioContext;
    destination: MediaStreamAudioDestinationNode;
  };
  onAudioPlay?: Function;
}

const AudioPlayer = ({
  audioFile,
  audioContext,
  onAudioPlay = () => {},
}: AudioPlayerParams) => {
  const [status, setStatus] = useState('INIT');
  const [error, setError] = useState('');
  const [buffer, setBuffer] = useState<AudioBuffer>();

  const decodeAudio = async (context: AudioContext, data: ArrayBuffer):Promise<AudioBuffer> => {
    // because of ios
    return new Promise((resolve, reject) => {
      context.decodeAudioData(
        data,
        (buffer) => {
          resolve(buffer);
        },
        (e) => {
          reject(e);
        },
      );
    });
  };

  if (status === 'INIT') {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (!event.target || !event.target.result) {
        setStatus('ERROR');
        setError('Read file error');
        Sentry.captureMessage(`Read file error ${audioFile.name}`);
        return;
      }
      setStatus('DECODING');
      try {
        const bufferData = await decodeAudio(
          audioContext.context,
          event.target.result as ArrayBuffer,
        );
        //   const bufferData = await audioContext.context.decodeAudioData(event.target.result as ArrayBuffer);
        setBuffer(bufferData);
      } catch (e) {
        setStatus('ERROR');
        setError(`Decode error: ${e.message}`);
        Sentry.captureException(e);
      }
      setStatus('READY');
    };
    reader.readAsArrayBuffer(audioFile);
    setStatus('READING');
    return null;
  }

  const onAudioClick = () => {
    if (status !== 'READY' || !buffer) {
      return;
    }

    const bufferSourceNode = audioContext.context.createBufferSource();
    bufferSourceNode.buffer = buffer;
    bufferSourceNode.connect(audioContext.context.destination);
    bufferSourceNode.connect(audioContext.destination);
    bufferSourceNode.start(0);
    onAudioPlay();
  };

  if (error) {
    // TODO
    return <div className={cls.audioPlayer}> {error}</div>;
  }

  return (
    <button className={cls.audioPlayer} onClick={onAudioClick}>
      {audioFile.name} {status}{' '}
    </button>
  );
};

export default AudioPlayer;
