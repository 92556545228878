import React from 'react';

import cls from './traybutton.module.scss';

interface TrayButtonPropps {
    children: any;
  active: boolean;
  onClick: any;
}

const TrayButton = ({active, onClick, children }: TrayButtonPropps) => {
  const classNames = [cls.trayButton];
  if (active) {
    classNames.push(cls.active);
  }
  return (
    <button onClick={onClick} className={classNames.join(' ')}>
        {children}
    </button>
  );
};

export default TrayButton;
