import React, { useRef, useEffect, useState } from 'react';

// import Spinner from '../../../../components/spinner/Spinner';
import TrayButton from '../TrayButton/TrayButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVideoSlash,
  faMicrophoneAltSlash,
  faCompress,
} from '@fortawesome/free-solid-svg-icons';

import cls from './tile.module.scss';

interface TaleProps {
  callItem: any;
  isLarge: boolean;
  isLocalPerson: boolean;
  isVideoMirrored: boolean;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }
}

const Tile = ({
  callItem,
  isLarge,
  isLocalPerson,
  isVideoMirrored,
}: TaleProps) => {
  const videoEl = useRef<any>(null);
  const audioEl = useRef<any>(null);
  const tileElement = useRef<any>(null);

  const [isVideoTrackEnabled, setIsVideoTrackEnabled] = useState(true);

  /**
   * When video track changes, update video srcObject
   */
  useEffect(() => {
    if (!videoEl.current) {
      return;
    }
    if (
      !isLocalPerson &&
      !isLarge &&
      Date.now() - callItem.joinedAt.getTime() - 5000
    ) {
      callItem.videoTrack.enabled = false;
    }
    videoEl.current.srcObject = new MediaStream([callItem.videoTrack]);
    setIsVideoTrackEnabled(callItem.videoTrack.enabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callItem.videoTrack]);

  /**
   * When audio track changes, update audio srcObject
   */
  useEffect(() => {
    audioEl.current &&
      (audioEl.current.srcObject = new MediaStream([callItem.audioTrack]));
  }, [callItem.audioTrack]);

  //   function getLoadingComponent() {
  //     return callItem.isLoading && <Spinner />;
  //   }

  function getVideoComponent() {
    // if (callItem.isLoading) {
    //   return false;
    // }
    const onContextMenu = (event: React.MouseEvent<HTMLVideoElement, MouseEvent>)=>{
        event.preventDefault();
        return false;
    }

    if (callItem.videoTrack) {
      return <video onContextMenu={onContextMenu} autoPlay muted playsInline ref={videoEl} />;
    }

    return false;
  }

  function getStatusIcons() {
    // if (callItem.isLoading) {
    //   return false;
    // }

    const icons = [];

    if (!callItem.videoTrack) {
      icons.push(
        <FontAwesomeIcon
          icon={faVideoSlash}
          className={cls.videoDisabled}
          key={icons.length}
        />,
      );
    }

    if (!callItem.audioTrack) {
      icons.push(
        <FontAwesomeIcon
          key={icons.length}
          icon={faMicrophoneAltSlash}
          className={cls.videoDisabled}
        />,
      );
    }

    if (icons.length) {
      return <div className={cls.statusIcons}>{icons}</div>;
    }
  }

  function getAudioComponent() {
    // if (callItem.isLoading) {
    //   return false;
    // }

    if (callItem.audioTrack && !isLocalPerson) {
      return <audio autoPlay playsInline ref={audioEl} />;
    }

    return false;
  }

  function getParticipentInfoComponent() {
    const userInfo = [callItem.userName];
    if (isLocalPerson) {
      userInfo.push('(YOU)');
    }
    return <div className={cls.participentInfo}>{userInfo.join(' ')} </div>;
  }

  function getClassNames() {
    let classNames = [cls.tile];
    if (isLarge) {
      classNames.push(cls.large);
    } else {
      classNames.push(cls.small);
    }
    if (isLocalPerson) {
      classNames.push(cls.local);
      if (isVideoMirrored) {
        classNames.push(cls.mirror);
      }
    }
    return classNames.join(' ');
  }

  const toggleVideo = () => {
    callItem.videoTrack.enabled = !callItem.videoTrack.enabled;
    setIsVideoTrackEnabled(callItem.videoTrack.enabled);
  };

  const toggleFullScreen = () => {
    const fullScreenElement = document.fullscreenElement || document.webkitFullscreenElement;
    const elem = tileElement.current;
    if (!fullScreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else {
        // we on mobile safari
        videoEl.current.webkitEnterFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  };

  const getParticipentTrayComponent = () => {
    // if (isLocalPerson) {
    //   return false; // no track for local person
    // }
    return (
      <div className={cls.tray}>
        {callItem.videoTrack && !isLocalPerson && (
          <TrayButton active={!isVideoTrackEnabled} onClick={toggleVideo}>
            <FontAwesomeIcon icon={faVideoSlash} />
          </TrayButton>
        )}
        <TrayButton active={false} onClick={toggleFullScreen}>
          <FontAwesomeIcon icon={faCompress} />
        </TrayButton>
      </div>
    );
  };

  return (
    <div
      className={getClassNames()}
      ref={tileElement}
      onDoubleClick={toggleFullScreen}
    >
      <div className={cls.background} />
      {/* {getLoadingComponent()} */}
      {getVideoComponent()}
      {getAudioComponent()}
      {getParticipentInfoComponent()}
      {getParticipentTrayComponent()}
      {getStatusIcons()}
    </div>
  );
};

export default Tile;
