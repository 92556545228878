import React from 'react';
import classnames from 'classnames';
import cls from './spinner.module.scss';

import logo from '../../img/logo.svg';
const Spinner = ({noAnimation}:{noAnimation?:boolean}) => {
  const classNames = classnames({
    [cls.spinner]: true,
    [cls.noAnimation]:  noAnimation
  })
  return (
      <img  className={classNames} src={logo} alt="Loaging...." />
  );
};

export default Spinner;
