import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// @ts-ignore
import DailyIframe from '@daily-co/daily-js/src/main';
import * as Sentry from '@sentry/browser';

import { version } from '../../../package.json';


import Spinner from '../../components/spinner/Spinner';
import Notifier from '../../components/notifier/Notifier';

import JoinDialog from './components/JoinDialog/JoinDialog';
import Tray from './components/Tray/Tray';
import Call from './components/Call/Call';
import AudioPanel from './components/AudioPanel/AudioPanel';

import { DailyError } from '../../DailyTypes';

declare global {
  interface Window {
    webkitAudioContext: AudioContext;
    destinationStream: any;
    store: any;
  }
}

const STATE_IDLE = 'STATE_IDLE';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
//const STATE_LEAVING = 'STATE_LEAVING';
const STATE_LEFT = 'STATE_LEFT';
const STATE_ERROR = 'STATE_ERROR';

const JoinPage = () => {
  const [error, setError] = useState('');
  const [meetingState, setMeetingState] = useState(STATE_IDLE);
  const [callObject, setCallObject] = useState({});
  const [isVideoMirrored, setIsVideoMirrored] = useState(true);
  const [audioContext, setAudioContext] = useState<any>({
    context: null,
    destination: null,
  });

  let { roomName, meetingToken } = useParams();

  if (!DailyIframe.supportedBrowser().supported) {
    if (meetingState !== STATE_ERROR) {
      console.log('not supported', meetingState);
      Sentry.captureMessage('Browser not suported');
      setError('Browser not suported');
      setMeetingState(STATE_ERROR);
    }
  }

  const joinMeeting = async (userName:string) => {

    setMeetingState(STATE_JOINING);

    const AC = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AC();
    const destinationStream = audioCtx.createMediaStreamDestination();
    setAudioContext({
      context: audioCtx,
      destination: destinationStream,
    });
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const mic = audioCtx.createMediaStreamSource(stream);
        mic.connect(destinationStream);
        // debugger
        // callObject.setInputDevices({
        //   audioSource: destinationStream.stream.getAudioTracks()[0]
        // })
      },(error)=>{
        console.error(error);
        Sentry.captureException(error)
      });

    // Daily hack
    // const enumerateDevicesOriginal = navigator.mediaDevices.enumerateDevices;

    // navigator.mediaDevices.enumerateDevices = () => {
    //   return enumerateDevicesOriginal
    //     .call(navigator.mediaDevices)
    //     .then((devices: any) => {
    //       devices.push({
    //         deviceId: destinationStream.stream.getAudioTracks()[0],
    //         label: 'custom',
    //         kind: 'audioinput',
    //       });
    //       return devices;
    //     });
    // };

    const newCallObject = DailyIframe.createCallObject({
      audioSource: destinationStream.stream.getAudioTracks()[0],
    });
    setCallObject(newCallObject);

    const url = `https://insailing.daily.co/${roomName}`;
    newCallObject.on('error', (error: DailyError) => {
      setError(error.errorMsg);
      Sentry.captureMessage(error.errorMsg);
      setMeetingState(STATE_ERROR);
      console.log('error', error);
    });

    newCallObject.join({ url, token: meetingToken }).then(
      (res: object) => {
        Sentry.init({ // daily hack
          dsn: process.env.REACT_APP_SENTRY_DSN,
          release: `videoquests-frontend@${version}`,
          environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
          maxBreadcrumbs: 50
        });
        setMeetingState(STATE_JOINED);
        // todo dayly hack
        try {
          window.store.getState().local.public.devices.audio = null;
          window.store.dispatch({type:"local.NAME_UPDATED", name:encodeURI(userName)});
          newCallObject.participants().local.user_name  = userName;

        } catch (e) {
          console.error('Daily hack broken :(');
          Sentry.captureMessage('Daily hack broken :(');

          Sentry.captureException(e);
        }
      },
      (err: object) => {
        console.log(err);
        Sentry.captureMessage(error);
        debugger;
      },
    );
  };

  // useEffect(() => {
  //   console.log('join');
  //   joinMeeting(); // start meeting on enter
  // });

  if (error) {
    return <Notifier message={error} type="error" />;
  }

  if (meetingState === STATE_IDLE){
    return <JoinDialog  meetingToken={meetingToken} onJoin={joinMeeting} />
  }

  if ([STATE_JOINING].indexOf(meetingState) !== -1) {
    return <Spinner />;
  }

  if (meetingState === STATE_LEFT) {
    return <Notifier message="Game is over. Thank you!" />;
  }

  if (meetingState === STATE_JOINED) {
    return (
      <React.Fragment>
        <Call callObject={callObject} isVideoMirrored={isVideoMirrored} />
        <Tray
          callObject={callObject}
          isVideoMirrored={isVideoMirrored}
          setIsVideoMirrored={setIsVideoMirrored}
        />
        <AudioPanel audioContext={audioContext} callObject={callObject}/>
      </React.Fragment>
    );
  }

  return <Notifier message="Unknown error" type="error" />;
};

export default JoinPage;
