import React, { useState } from 'react';
import classnames from 'classnames';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';



import AudioPlayer from '../AudioPlayer/AudioPlayer';

import cls from './audiopanel.module.scss';

interface AudioPanelParams {
  callObject: any;
  audioContext: {
    context: AudioContext;
    destination: MediaStreamAudioDestinationNode;
  };
}

const AudioPanel = ({ audioContext, callObject }: AudioPanelParams) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [audioFiles, setAudioFiles] = useState<File[]>([]);

  if (!callObject.participants().local.owner) {
    // owners only
    return <div></div>;
  }

  const onAudioPlay = () => {
    if (!callObject.participants().local.audio) {
      toast.error("Only you hear that sound. Please unmute youself", {
        position: 'top-center'
      });

    }
  };

  const onFileUploaded = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAudioFiles([
      ...audioFiles,
      ...Array.from(event.currentTarget.files || []),
    ]);
  };

  const classes = classnames({
    [cls.audioPlayer]: true,
    [cls.open]: isPanelOpen,
  });

  return (
    <div className={classes}>
      <button
        className={cls.panel}
        onClick={() => {
          setIsPanelOpen(!isPanelOpen);
        }}
      >
        <FontAwesomeIcon icon={faFileAudio} />
      </button>
      <div className={cls.tracks}>
        {audioFiles.map((file, key) => {
          return (
            <AudioPlayer
              audioFile={file}
              key={key}
              audioContext={audioContext}
              onAudioPlay={onAudioPlay}
            />
          );
        })}
      </div>
      <label className={cls.uploader}>
        Click to upload audio
        <input
          type="file"
          accept="audio/*"
          multiple
          onChange={onFileUploaded}
        ></input>
      </label>
    </div>
  );
};

export default AudioPanel;
