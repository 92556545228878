import React from 'react';

import cls from './notifier.module.scss'


const Notifier = ({message, type}:{message:string, type:string})=>{
    return(
        <div data-type={type} className={cls.notifier}>{message}</div>
    )
}

Notifier.defaultProps = {
    type: "info"
  };

export default Notifier;